import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import CustomerFeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import FeaturesGrid from 'components/content-blocks/features-grid';
import Highlight from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import Image from 'components/content-blocks/partials/image';
import ProductVideo from 'components/content-blocks/product-video';
import Yoast from 'components/globals/yoast';
import { HeroLanding } from 'components/hero/landing';

import './styles/boston.scss';

export default function Boston() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "boston" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                background_desktop_image {
                  source_url
                }
                background_mobile_image {
                  source_url
                  alt_text
                }
                title
                signup {
                  legal_text
                  modal_submit_button_text
                }
              }

              video_block_1 {
                video_id
                image_desktop {
                  source_url
                }
                image_mobile {
                  source_url
                }
              }
              media_object {
                body
                sub_title
                title
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                button_url
                button_text
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              customer_feature_benefit {
                sub_title
                title
                copy
                customer {
                  business
                  name
                  photo_desktop {
                    source_url
                  }
                  photo_mobile {
                    source_url
                  }
                  since
                }
                stats {
                  value_1
                  descriptor_1
                  value_2
                  descriptor_2
                  value_3
                  descriptor_3
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                  copy
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const cards1 = pageContext.cards_1;
  const productVideo = pageContext.video_block_1;
  const featuresGrid = pageContext.features_grid;
  const customerCard = pageContext.customer_feature_benefit;
  const mediaObject = pageContext.media_object;

  return (
    <Layout
      signupModalSubmit={ hero.signup.modal_submit_button_text }
      legalText={ hero.signup.legal_text }
    >
      <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
      <div className="boston-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroLanding
          { ...hero }
          defaultLandingHero={ pageContext.default_landing_hero_partial }
          section="featuresSchedulingHero"
        >
          <div className="has-text-centered">
            <Image
              src={ pageContext.hero.background_desktop_image.source_url }
              alt={ pageContext.hero.background_mobile_image.alt_text }
              desktopHeight="593"
              desktopWidth="1100"
            />
          </div>
        </HeroLanding>
        <Highlight { ...cards1 } hasTitle hasImage />
        <ProductVideo { ...productVideo } />
        <FeaturesGrid { ...featuresGrid[0] } />
        <CustomerFeatureBenefit { ...customerCard[0] } />
        <MediaObject { ...mediaObject[0] } />
      </div>
    </Layout>
  );
}
