import './styles/background-curve-cover.scss';

type BackgroundCurveCoverSVGProps = {
  className: string
}

const BackgroundCurveCoverSVG = ({ className }:BackgroundCurveCoverSVGProps) => (
  <svg
    className={ `background-curve-cover ${className && className}` }
    preserveAspectRatio="none"
    viewBox="0 0 1440 211"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Homepage"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(0.000000, -1923.000000)" id="Product">
        <g transform="translate(0.000000, 723.000000)">
          <g id="Group-22" transform="translate(0.000000, 1200.000000)">
            <path
              d="M1440,0 C1313.33333,133.333333 1073.33333,200 720,200 C366.666667,200 126.666667,133.333333 0,0 L0,211 L1440,211 L1440,0 Z"
              id="Mask"
              fill="#FFFFFF"
            ></path>
            <path
              d="M0,0.114886874 C126.666667,120.757046 366.666667,181.078125 720,181.078125 C1073.33333,181.078125 1313.33333,120.757046 1440,0.114886874 C1313,134.038296 1073,201 720,201 C367,201 127,134.038296 0,0.114886874 Z"
              id="Path-4"
              fillOpacity="0.07"
              fill="#000000"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BackgroundCurveCoverSVG;
