import './styles/product-video.scss';
import WistiaVideo from '../video/wistia-player';
import BackgroundCurveCoverSVG from './partials/background-curve-cover';

type ProductVideoProps = {
  id: string,
  image_desktop: {
    source_url: string
  },
  image_mobile: {
    source_url: string
  },
  screenshot: string,
  video_id: string
}
const ProductVideo = ({
  video_id,
  screenshot,
  image_desktop,
  image_mobile,
  id,
}:ProductVideoProps) => (
  <section
    id={ id }
    className="product-video-section section video-section is-clipped"
  >
    <div className="video-computer">
      <div className="video-screen">
        <div className="video-player">
          {!screenshot ? ( // to use a screenshot in place of a video add the screenshot prop with a value to the product-video component when used
            <WistiaVideo hashedId={ video_id || 'vlucuc14wi' } />
          ) : (
            <div className="screenshot-container">
              <div className="screenshot-responsive-padding">
                <img loading="lazy" src={ screenshot } className="screenshot" />
              </div>
            </div>
          )}
        </div>
        <img loading="lazy" src={ image_desktop.source_url } />
      </div>
      <img
        loading="lazy"
        className="video-stand is-hidden-mobile"
        src={ image_mobile.source_url }
      />
      <BackgroundCurveCoverSVG className="video-curve" />
    </div>
  </section>
);

export default ProductVideo;
